import {Typography} from "@ornament-ui/kit/Typography";
import styles from "./paymentMethod.module.scss";
import SuccessTitle from "../SuccessTitle";
import {BanIcon, CloseEyesIcon, TickIcon} from "@ornament-ui/icons";
import sbpBio from "./assets/sbp.svg";
import errorPayIcon from "../HistoryComponents/Components/assets/error.svg";
import React, {useContext, useEffect, useState} from "react";
import {Skeleton} from "@ornament-ui/kit/__inner__/esm/components/Skeleton";
import {useIsMobile} from "../../../utils/hooks/useIsMobile";
import {store} from "../../../../store/store";
import {DebtPaymentsDto, Page, ProfileDto, Vectors} from "../../../../types";
import {observer} from "mobx-react";
import {pages} from "../../../utils/constants";
import {Divider} from "@ornament-ui/kit/__inner__/esm/components/Divider";
import {Button} from "@ornament-ui/kit/__inner__/esm/components/Button";
import {toJS} from "mobx";
import TypeBio from "../TypeBio";
import SkeletonCard from "../SkeletonCard/SkeletonCard";
import PermissionCard from "../PermissionCard/PermissionCard";
import BioTypeModal from "../BioTypeModal";
import {OutletContextType, OutletUseContext} from "../../MainPage";
import profileContext, {getDateTime} from "../../../../services/profileContext";
import BlackListPayModal from "../BlackListPayModal";
import {useNavigate} from "react-router-dom";


const PaymentMethod = observer(() => {
    const [isOpenBioTypeModal, setIsOpenBioTypeModal] = useState<boolean>(false);
    const [isOpenBlackListPayModal, setIsOpenBlackListPayModal] = useState<boolean>(false);
    const [activeItemBlackListPay, setActiveItemBlackListPay] = useState<DebtPaymentsDto | null>(null);
    const [typeBioModal, setTypeBioModal] =
        useState<Vectors["displayedBioType"]>("Упрощенная");
    const [data, setData] = useState<ProfileDto | null>(null);
    const [sbpStatus, setSbpStatus] = useState<boolean>(false);
    const [errorPay, setErrorPay] = useState<DebtPaymentsDto[] | null>(null);
    const isMobile = useIsMobile();
    const navigate = useNavigate();
    const {
        setConnectionDataCandidate,
        instancesConnected,
        instancesRequests,
        isLoadingInstances
    } = useContext<OutletContextType>(OutletUseContext);


    useEffect(() => {
        profileContext.getDebtPayments()
            .then((res) => {
                if (res.data && res.data.length > 0) {
                    setErrorPay(res.data)
                } else if (errorPay && errorPay?.length > 0 && res.data.length === 0) {
                    setErrorPay(null)
                }
            })
    }, [isOpenBlackListPayModal])


    useEffect(() => {
        if (store.profile) {
            setSbpStatus(toJS(store.profile.isSbpConnected))
            setData(store.profile);
        }
    }, [store.getProfile]);


    return (
        <>
            <Typography
                className={styles.permissions__title}
                variant={"heading-2xl"}
            >
                Ваш профиль
            </Typography>

            <div className={styles.payment}>
                <Typography variant="heading-xl" style={{marginBottom: "16px"}}>
                    Способ оплаты
                </Typography>

                <div className={styles.payment__wrp}>
                    <div
                        className={styles.payment__block}
                        style={{background: errorPay ? "#FB002F1A" : "#FFFFFF"}}
                    >
                        {data ? (
                            <div style={{display: "flex", gap: "12px", flexDirection: isMobile ? "column" : "row"}}>
                                <div style={{display: "flex", flexDirection: "column"}}>
                                    <div className={styles.payment__wrp}>
                                        <img
                                            width={29}
                                            src={sbpBio}
                                            alt="Изображение СБП"
                                            className={styles.payment__img}
                                        />
                                        <div className={styles.payment__info}>
                                            <Typography variant="text-m_strong">
                                                Система быстрых платежей
                                            </Typography>
                                            {errorPay ? (
                                                <SuccessTitle
                                                    text={"Карта в стоп листе"}
                                                    icon={<BanIcon
                                                        style={{minWidth: '26px'}}
                                                        color="#FB002F"/>}
                                                    color={"#FB002F"}
                                                />
                                            ) : (
                                                <SuccessTitle
                                                    text={
                                                        sbpStatus ? "Подключена к системе" : "Не подключена"
                                                    }
                                                    icon={
                                                        sbpStatus ? (
                                                            <TickIcon size="m" color="#04C300"/>
                                                        ) : (
                                                            <CloseEyesIcon size="m" color="#DAA419"/>
                                                        )
                                                    }
                                                    color={sbpStatus ? "#04C300" : "#DAA419"}
                                                />
                                            )}
                                        </div>
                                    </div>

                                    {!sbpStatus && (
                                        <Typography
                                            style={{
                                                marginTop: "12px",
                                                color: "#893EEC",
                                                cursor: "pointer",
                                                width: "max-content",
                                            }}
                                            variant="text-m"
                                            onClick={() => {
                                                const page: Page = pages.find(
                                                    (item) => item.value === "help"
                                                ) as Page;
                                                navigate('/help/connect')
                                                store.setPage(page);
                                            }}
                                        >
                                            Как подключить
                                        </Typography>
                                    )}
                                    {
                                        errorPay &&
                                        <Button
                                            variant={"ghost"}
                                            size={isMobile ? "xs" : "s"}
                                            className={styles.btn}
                                            style={{
                                                fontSize: "16px",
                                                background: "#893EEC",
                                                color: "white",
                                                borderRadius: "8px",
                                                marginTop: "auto"
                                            }}
                                            onClick={() => {
                                                setActiveItemBlackListPay(errorPay[0])
                                                setIsOpenBlackListPayModal(true)
                                            }}
                                        >
                                            Оплатить сейчас
                                        </Button>
                                    }
                                </div>
                                {errorPay && (
                                    <>
                                        <Divider orientation="vertical" flexItem={true}/>
                                        <div>
                                            <div style={{display: "flex", gap: "12px", alignItems: 'center'}}>
                                                <img
                                                    width={38}
                                                    src={errorPayIcon}
                                                    alt="Изображение СБП"
                                                    className={styles.payment__img}
                                                    style={{marginBottom: "8px"}}
                                                />
                                                <Typography
                                                    style={{marginBottom: "4px"}}
                                                    variant="text-m_strong"
                                                >
                                                    {errorPay[0].amount / 100} ₽
                                                </Typography>
                                            </div>
                                            <Typography
                                                style={{marginBottom: "2px"}}
                                                variant="text-s"
                                            >
                                                {errorPay[0].text}
                                            </Typography>
                                            <Typography
                                                color={'disabled'}
                                                style={{marginBottom: "2px"}}
                                                variant="text-s"
                                            >
                                                {getDateTime(errorPay[0].time)}
                                            </Typography>
                                            <Typography variant="text-s" color="error">
                                                Оплата не прошла
                                            </Typography>
                                        </div>
                                    </>
                                )}
                            </div>
                        ) : (
                            <>
                                <Skeleton
                                    className={styles.skeleton__icon}
                                    variant={"rectangular"}
                                    width={isMobile ? 34 : 34}
                                    height={isMobile ? 34 : 34}
                                />
                                <div className={styles.payment__info}>
                                    <Skeleton
                                        className={styles.skeleton__title}
                                        variant={"rectangular"}
                                        width={205}
                                        height={12}
                                    />
                                    <Skeleton
                                        className={styles.skeleton__satus}
                                        variant={"rectangular"}
                                        height={10}
                                        width={158}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>

            <TypeBio
                setIsOpenBioTypeModal={setIsOpenBioTypeModal}
                setTypeBioModal={setTypeBioModal}
            />

            <div className={styles.cardListContainer}>
                <Typography variant="heading-xl" style={{marginBottom: "16px"}}>
                    Подключенные объекты
                </Typography>
                <ul className={styles.cardList}>
                    {isLoadingInstances ? (
                        <>
                            <SkeletonCard/>
                        </>
                    ) : instancesConnected?.length ? (
                        instancesConnected.map((data) => {
                            return (
                                <PermissionCard
                                    key={data.id}
                                    data={data}
                                    setConnectionDataCandidate={setConnectionDataCandidate}
                                />
                            );
                        })
                    ) : (
                        <Typography
                            className={styles.notFilterFoundText}
                            variant={"heading-xl"}
                            color={"tertiary"}
                        >
                            Пока нет подключенных объектов
                        </Typography>
                    )}
                </ul>
                <ul className={styles.cardList}>
                    <Typography variant="heading-xl" style={{marginTop: "20px"}}>
                        Запросы на подключение
                    </Typography>

                    {isLoadingInstances ? (
                        <>
                            <SkeletonCard/>
                        </>
                    ) : instancesRequests?.length ? (
                        instancesRequests.map((data) => {
                            return (
                                <PermissionCard
                                    key={data.id}
                                    data={data}
                                    setConnectionDataCandidate={setConnectionDataCandidate}
                                />
                            );
                        })
                    ) : (
                        <Typography
                            className={styles.notFilterFoundText}
                            variant={"heading-xl"}
                            color={"tertiary"}
                        >
                            Пока нет запросов на подключение
                        </Typography>
                    )}
                </ul>
            </div>
            {isOpenBioTypeModal && (
                <BioTypeModal
                    isOpen={isOpenBioTypeModal}
                    closeModal={setIsOpenBioTypeModal}
                    type={typeBioModal}
                />
            )}
            {
                isOpenBlackListPayModal &&  <BlackListPayModal
                    isOpen={isOpenBlackListPayModal}
                    closeModal={setIsOpenBlackListPayModal}
                    activeItemBlackListPay={activeItemBlackListPay}
                    setActiveItemBlackListPay={setActiveItemBlackListPay}
                />
            }
        </>
    );
});

export default PaymentMethod;
